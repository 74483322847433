

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css";
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins/Poppins-Regular.otf) format("truetype");
}



html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "Poppins" !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mat-button,
.mat-menu-item {
  // background-color: #000096;
  // opacity: 0.7;
  color: black;
  font-family: "Poppins";
  font-family: "Poppins" !important;
  // font-size: 0.75vw;
}

.mat-button,
.mat-raised-button {
  background-color: #000096;
  // opacity: 0.7;
  color: white;
  font-family: "Poppins";
  // font-size: 0.8vw;
  overflow-y: hidden;
}

.custom-tooltip{
  // background-color: white;
  //color: #000096 !important;
  font-size: 18px;

    white-space: pre-wrap;
    background-color: #000096 !important;
    border:1px solid #D6D8E1;
    max-width: unset !important;
    // max-width: 400px;
}


 @media (min-width: 30px) {
  /* .mat-button,
  .mat-raised-button {
    font-size: 0.75vw;
  } */
}

.mat-expansion-panel-body {
  background-color: #000096;
  .mat-icon {
    width: 35px;
  }
}

mat-panel-title {
  font-family: "Poppins" !important;
  color: #000096;
  font-size: 14px;
  vertical-align: middle !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

mat-expansion-panel-header {
  height: 70px;
}

mat-expansion-panel {
  font-family: "Poppins" !important;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
  justify-content: center;
  text-align: justify;
  text-justify: inter-word;
  .mat-form-field,
  .mat-option,
  .mat-select {
    font-family: "Poppins" !important;
  }
  .mat-icon {
    width: 35px;
  }
  background-color: white;
}

mat-accordion {
  height: 80%;
  max-height: 80%;
  // margin-top: 50px !important;
  margin: 0px;
  padding: 0px;
}

mat-form-field.mat-form-field.mat-white {
  &.mat-focused {
    .mat-form-field-label {
      color: #fafafa;
      // background-color: #fafafa !important;
      font-family: "Poppins" !important;
      // font-size: 14px;
    }
    .mat-form-field-ripple {
      color: #fafafa;
      background-color: #fafafa !important;
      font-family: "Poppins" !important;
    }
  }
  .mat-form-field-label,
  .mat-form-field-label.mat-focused,
  .mat-form-field.mat-focused.matform-field-should-float {
    font-family: "Poppins" !important;
    color: #fafafa;
    font-size: 14px;
    // background-color: yellow;
  }
  .mat-form-field-underline,
  .mat-form-field-underline.mat-focused {
    color: #fafafa;
      background-color: #fafafa !important;
      font-family: "Poppins" !important;
  }

  .mat-field{
    font-size: 14px;
  }
  .mat-select.mat-select-panel {
    max-width: 30px !important;
    margin-left: 10px;
    background-color: #fafafa;
  }

  .mat-select.mat-select-content{
    width:20px;
    background-color: black;
    font-size: 10px;
  }
  .mat-select-trigger {
    width: 250px;
  }
  .mat-select.mat-option {
    // background-color: green;
    // background-color: red;
    font-family: "Poppins" !important;
  }
  .mat-select-value {
    color: white !important;;
  }

}

mat-form-field.mat-form-field.mat-white2 {
  &.mat-focused {
    .mat-form-field-label {
      // color: #fafafa;
      // background-color: #fafafa !important;
      font-family: "Poppins" !important;
      // font-size: 14px;
    }
    .mat-form-field-ripple {
      // color: #fafafa;
      // background-color: #fafafa !important;
      font-family: "Poppins" !important;
    }
  }
  .mat-form-field-label,
  .mat-form-field-label.mat-focused,
  .mat-form-field.mat-focused.matform-field-should-float {
    font-family: "Poppins" !important;
    // color: #fafafa;
    font-size: 10px;
    // background-color: yellow;
  }
  .mat-form-field-underline,
  .mat-form-field-underline.mat-focused {
    // color: #fafafa;
      // background-color: #fafafa !important;
      font-family: "Poppins" !important;
  }

  .mat-field{
    font-size: 10px;
    height: 10px;
  }
  .mat-select.mat-select-panel {
    max-width: 10px !important;
    margin-left: 10px;
    height: 10px;
    // background-color: #fafafa;
  }

  .mat-select.mat-select-content{
    width:10px;
    background-color: black;
    font-size: 10px;
  }
  .mat-select-trigger {
    width: 50px;
    height: 10px;
  }
  .mat-select.mat-option {
    // background-color: green;
    // background-color: red;
    font-family: "Poppins" !important;
  }
  /* .mat-select-value {
    // color: white !important;;
  } */
  width: 100px;
  // height: 10px;
}

.mat-form-field, .mat-label{
  width: 100%;

}
.mat-select-arrow {
  color: white;
}

.mat-form-field-underline .mat-form-field-ripple{ background: white; }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: white;
}
.mat-radio-button {
  font-size: 16px;
  display: flex;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: white;
}

.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: white;
}

 .mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: white;
  background-color: white;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}



.mat-checkbox .mat-checkbox-frame {
  border-color: #000096;
}

.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #000096 !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  display:none;
}

::ng-deep * {
  font-family: "Poppins" !important;
}

//Estilo clase definido para el dibujo de las paradas.
.marker {
  // background-image: url('./assets/logios.png');
  background-color: #000096;
  background-size: cover;
  max-width: 3%;
  width: 1vh;
  height: 1vh;
  border-radius: 50%;
  cursor: pointer;
}

.markerEnds {
  // background-image: url('./assets/logios.png');
  background-color: #F5821E;
  background-size: cover;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.busanimado {
  // background-image: url('./assets/logios.png');
  // background-color: #000096;
  // background-size: cover;
  display: block;
  border: none;
  padding: 0;

  width: 18px;
  height: 23.5px;
  // border-radius: 50%;
  cursor: pointer;
}

.busanimado2 {
  // background-image: url('./assets/logios.png');
  // background-color: #000096;
  // background-size: cover;
  display: block;
  border: none;
  padding: 0;

  width: 30px;
  height: 30px;
  // border-radius: 50%;
  cursor: pointer;
}


// For outer circle
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:white;
}


.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:white;
  // background-color:white ;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:white;
}


.markerDepotimg{
  background-image: url('./assets/depotdos.png');
  //background-color:blueviolet;
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

//css del popup
.custom-popup>.mapboxgl-popup-content{
  background-color: #000096;
  width: 550px !important;
  height: 350px !important;
}
